/*
*
* Isotope
*/

$isotope-default-color: $gray-300;
$isotope-default-color-active: $primary;
$isotope-default-border: $isotope-default-color-active;
$isotope-nav-spacing: 5px;

$isotope-inverse-color: $white;
$isotope-inverse-color-active: $primary;
$isotope-inverse-border: $isotope-inverse-color-active;

//
// Isotope animations
//
@keyframes sprite-animation {
	from {
		background-position: 0 0;
	}
}

[data-isotope-layout] {
	z-index: 0;
	display: block;
	min-height: 160px;
	margin-left: -15px;
	margin-right: -15px;
	transition: .4s all ease;

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 64px;
		height: 64px;
		margin-top: 15px;
		background-image: url("../images/isotope-loader.png");
		background-position: -1152px 0;
		animation: 0.7s sprite-animation steps(18) infinite;
		transition: .4s all ease;
		transform: translate3d(-50%, -50%, 0);
	}

	.row {
		> [class*="col-"] {
			margin-top: 30px;
		}
	}

	.row-narrow {
		margin-bottom: -$grid-gutter-width-narrow;

		> [class*="col-"] {
			margin-top: $grid-gutter-width-narrow;
		}
	}

	.row-condensed {
		margin-bottom: 0;

		> [class*="col-"] {
			margin-top: 0;
		}
	}

	[class*="col-"] {
		display: block;
		opacity: 0;
		will-change: transform;
		backface-visibility: hidden;
		transition: .1s opacity ease-in;

		@include media-breakpoint-down(sm) {
			max-width: 100%;
		}
	}

	&.isotope--loaded {
		[class*="col-"] {
			opacity: 1;
		}

		&:after {
			opacity: 0;
			visibility: hidden;
		}
	}
}

.isotope-filters-toggle {
	display: none;
}

// Isotope defaults
.isotope-filters {
	$value: 10px;

	> * {
		margin-top: 0;
		vertical-align: middle;
	}

	.inline-list {
		position: relative;
		word-spacing: 0;

		li {
			display: inline-block;
		}

		a {
			position: relative;
			transition: .3s;
			color: $isotope-default-color;
		}

		.isotope-filters-trigger {
			display: none;
		}
	}
}

// Medium <768px
@include media-breakpoint-down(sm) {
	.isotope-filters-list {
		text-align: center;

		> li > a {
			display: block;
			padding: 3px 15px;
			background: $primary;
			color: $white;
			border-radius: $border-radius-main;

			&:hover,
			&:focus,
			&.active {
				background: $primary-dark;
			}
		}

		> li + li {
			margin-top: 1px;
		}
	}
}

// Medium ≥768px
@include media-breakpoint-up(md) {
	.isotope-filters-line {
		.isotope-filters-list {
			border-bottom: 1px solid rgba($gray-700, .1);

			> li {
				display: inline-block;
				margin-right: 25px;

				&:last-child {
					margin-right: 0;
				}

				> a {
					display: block;
					margin-bottom: -1px;
					padding-bottom: 6px;
					color: $gray-600;
					border-bottom: 1px solid transparent;

					&:hover,
					&:focus,
					&.active {
						color: $primary;
						border-bottom: 1px solid $primary;
					}
				}
			}
		}
	}

	.isotope-inline-filters {
		.isotope-filters-list {
			display: inline-block;
		}
	}
}


.isotope-filters-modern {
	.inline-list {
		@include spacing(20px, 10px);

		> li {
			font-family: $font-family-sec;
			font-size: 18px;
		}

		a {
			position: relative;
			padding-bottom: 10px;

			&::after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 0;
				height: 2px;
				transition: .4s;
				background: $primary;
			}
		}

		a:hover {
			color: $isotope-default-color-active;
		}

		a.active {
			&::after {
				width: 100%;
			}
		}

		@include media-breakpoint-up(lg) {
			@include spacing(45px, 10px);
		}
	}
}

.isotope-modern-wrap {
	[class*='col'] {
		margin-top: 30px;
	}

	@include media-breakpoint-down(sm) {
		[class*='col'] {
			width: 100%;
			max-width: 100%;
		}
	}

	html:not(.tablet):not(.mobile) & {
		@include media-breakpoint-up(lg) {
			[class*='col'] {
				margin-top: 0;
			}
		}
	}
}

.isotope-condensed {
	margin-left: 0;
	margin-right: 0;
	.row > * {
		margin-top: -1px;
		margin-bottom: -1px;
	}
}

* + .isotope {
	margin-top: 0;
}

* + .isotope-condensed {
	margin-top: 30px;
}

@include media-breakpoint-down(md) {
	.isotope-condensed {
		padding: 0 0 30px;
		margin-left: 0;
		margin-right: 0;
		.row.row-condensed > [class*='col'] {
			padding-left: 10px;
			padding-right: 10px;
		}

		.row.row-condensed > [class*='col'] + [class*='col'] {
			margin-top: 25px;
		}

	}
}

@include media-breakpoint-up(md) {
	* + .isotope {
		margin-top: 28px;
	}
	* + .isotope-condensed {
		margin-top: 50px;
	}
}
