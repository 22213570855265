/*
*
* Boxes
*/

// 
// Table of Contents:
//
// Box Minimal
// Box Counter

//
// Box Minimal
//
.box-minimal {
	position: relative;
	display: block;
	padding: 30px 20px;
	font-size: 18px;
	line-height: 1.3;
	background: $white;
	color: $primary;
	border-radius: $border-radius-sm;

	&:hover {
		color: $primary-dark;
		box-shadow: 0 0 24px 0 rgba($gray-600, .3);
	}

	.hot {
		position: absolute;
		top: 8px;
		left: 20px;
		padding: 3px 7px;
		font-size: 12px;
		font-weight: 700;
		line-height: 1;
		text-transform: uppercase;
		color: $white;
		background: $primary-dark;
	}
}

// Large ≥992px
@include media-breakpoint-up(lg) {
	.box-minimal {
		padding: 63px 15px 63px 40px;
		font-size: 25px;

		.hot {
			top: 16px;
			left: 40px;
			padding: 5px 15px;
			font-size: $font-size-base;
		}
	}
}

//
// Box Counter
//
.box-counter {
	position: relative;
	text-align: center;
	color: $gray-700;
}

.box-counter-title {
	display: inline-block;
	font-family: $font-family-sec;
	font-size: 16px;
	line-height: 1.3;
	letter-spacing: -.025em;
}

.box-counter-main {
	font-family: $font-family-sec;
	font-size: 45px;
	font-weight: 400;
	line-height: 1.2;

	> * {
		display: inline;
		font: inherit;
	}

	.small {
		font-size: 28px;
	}

	.small_top {
		position: relative;
		top: .2em;
		vertical-align: top;
	}
}

.box-counter-divider {
	font-size: 0;
	line-height: 0;
	&::after {
		content: '';
		display: inline-block;
		width: 80px;
		height: 2px;
		background: $primary;
	}
}

* + .box-counter-title {
	margin-top: 10px;
}

* + .box-counter-main {
	margin-top: 20px;
}

* + .box-counter-divider {
	margin-top: 10px;
}

@include media-breakpoint-up(md) {
	.box-counter-title {
		font-size: 18px;
	}
}

@include media-breakpoint-up(xl) {
	.box-counter-main {
		font-size: 60px;

		.small {
			font-size: 36px;
		}
	}

	* + .box-counter-main {
		margin-top: 30px;
	}
	* + .box-counter-divider {
		margin-top: 15px;
	}
	* + .box-counter-title {
		margin-top: 18px;
	}
}

// Context styling
.bg-primary {
	.box-counter {
		color: $white-invariable;
	}

	.box-counter-divider {
		&::after {
			background: $white-invariable;
		}
	}
}

// Contact box
.contact-box {
	> li + li {
		margin-top: 15px;
	}

	.icon {
		font-size: 24px;
		color: $primary;
	}

	.list-phones {
		> li + li { margin-top: 2px; }
	}
}

* + .contact-box { margin-top: 15px; }

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	* + .contact-box { margin-top: 30px; }
}

/*
Price box
*/

// Price box 1
.price-box-1 {
	position: relative;
	z-index: 2;
	padding: 25px 15px;
	background: $gray-100;
	border-radius: $border-radius-sm;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		border-radius: $border-radius-sm;
		background: inherit;
	}

	.title {
		font-size: 22px;
		line-height: 1.45;
		font-weight: 700;
		color: $gray-700;
	}

	.exeption {
		color: $gray-600;
	}

	&-primary {
		background: linear-gradient(to bottom, $primary, $primary-dark-2);

		.title,
		.exeption,
		.price {
			color: $white;
		}
	}

	* + .title { margin-top: 15px; }
	* + .exeption { margin-top: 12px; }
	* + .price { margin-top: 10px; }
	* + .button { margin-top: 12px; }

	@include media-breakpoint-up(xl) {
		margin-top: 26px;
		margin-bottom: 26px;
		padding: 45px 47px;

		&-primary {
			&:before {
				top: -26px;
				bottom: -26px;
			}
		}

		* + .title { margin-top: 24px; }
		* + .exeption { margin-top: 20px; }
		* + .price { margin-top: 18px; }
		* + .button { margin-top: 21px; }
	}

	@include media-breakpoint-up(xxl) {
		margin-top: 32px;
		margin-bottom: 32px;
		padding: 56px 60px;

		&-primary {
			&:before {
				top: -32px;
				bottom: -32px;
			}
		}

		* + .title { margin-top: 30px; }
		* + .exeption { margin-top: 25px; }
		* + .price { margin-top: 22px; }
		* + .button { margin-top: 26px; }
	}
}

// Price box 2
.price-box-2 {
	position: relative;
	z-index: 2;
	padding: 25px 15px;
	background: $white;
	border-radius: $border-radius-sm;

	.title {
		font-size: 22px;
		line-height: 1.45;
		font-weight: 700;
		color: $gray-700;
	}

	.exeption {
		color: $gray-600;
	}

	.price {
		font-weight: 100;
	}

	* + .title { margin-top: 15px; }
	* + .list-sm { margin-top: 11px; }
	* + .price { margin-top: 5px; }
	* + .button { margin-top: 14px; }

	@include media-breakpoint-up(xl) {
		padding: 35px 15px 50px;

		* + .title { margin-top: 30px; }
		* + .list-sm { margin-top: 21px; }
		* + .price { margin-top: 14px; }
		* + .button { margin-top: 31px; }
	}
}

/*
Box link
*/
.box-link {
	position: relative;
	z-index: 1;
	display: flex;
	overflow: hidden;
	text-align: center;
	color: $white;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: $border-radius-sm;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		background: rgba($primary, 0);
		transition: .3s;
	}

	&-inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-height: 100px;
		width: 100%;
		padding: 15px;
	}

	&:hover,
	&:focus,
	&:active {
		color: $white;

		&:before {
			background: rgba($primary, .4);
		}
	}

	// Large ≥992px
	@include media-breakpoint-up(md) {
		&-inner {
			min-height: 280px;
		}
	}
}

/*
Box wrapper
*/
.box-wrap-gray {
	padding: 15px 15px;
	background: $gray-100;
	border-radius: $border-radius-sm;

	@include media-breakpoint-up(sm) {
		padding: 35px;
	}

	@include media-breakpoint-up(xl) {
		padding: 47px 59px;
	}
}

/*
* Box content
*/
.box-content {
}

/*
* Timeline box
*/
.timeline {
	.date {
		font-weight: 500;
	}

	.line {
		position: relative;
		height: 17px;
		border-left: 1px solid $gray-400;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			height: 2px;
			transform: translateY(-50%);
			border-top: 1px solid $gray-400;
			width: 100vw;
		}
	}

	a.media-item {
		position: relative;
		overflow: hidden;
		display: block;
		border-radius: $border-radius-sm;
		max-width: 100px;

		&:before {
			content: "\f504";
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			transform: translate(-50%, -50%);
			font-family: $mdi;
			font-size: 30px;
			line-height: 1;
			color: $white;
			transition: .3s;
			will-change: transform;
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background: rgba($primary, 0.7);
			transition: .3s;
		}
	}

	* + .line { margin-top: 9px; }
	* + .media-item { margin-top: 19px; }
	* + .title { margin-top: 17px; }
	* + .exeption { margin-top: 17px; }

	.desktop & {
		a.media-item {
			&:before {
				color: transparent;
				transform: translate(-50%, -50%) scale(0.5);
			}

			&:after { background: rgba($primary, 0); }

			&:hover {
				&:before {
					color: $white;
					transform: translate(-50%, -50%) scale(1);
				}

				&:after { background: rgba($primary, 0.7); }
			}
		}
	}
}

/*
* Box inline
*/
.box-inline {
	text-align: center;

	.icon {
		font-size: 64px;
		line-height: 1;
	}

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		display: flex;
		align-items: center;
		justify-content: center;

		.box-inline-inner:first-child { padding-right: 15px; }
	}

	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		.box-inline-inner:first-child { padding-right: 42px; }
	}
}