/*
*
* Pagination custom
*/

//
// Pagination
//
$pagination-padding-y: 12px;
$pagination-padding-x: 12px;
$pagination-font-size: 16px;
$pagination-line-height: 24px;
$pagination-bradius: $border-radius-sm;

$pagination-color: $white;
$pagination-bg: $primary-light;
$pagination-border-width: 0;
$pagination-border-color: transparent;

$pagination-hover-color: $gray-700;
$pagination-hover-bg: $gray-100;
$pagination-hover-border-color: transparent;

$pagination-active-color: $gray-700;
$pagination-active-bg: $gray-100;
$pagination-active-border-color: transparent;

$pagination-disabled-color: $gray-400;
$pagination-disabled-bg: $gray-200;
$pagination-disabled-border-color: $gray-200;

.pagination {
	font-size: 0;
	line-height: 0;
	@include group(10px);
}

.page-item {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	font-weight: 700;
}

// Base styles
.page-link {
	position: relative;
	z-index: 1;
	display: inline-block;
	min-width: $pagination-padding-y*2 + $pagination-border-width*2 + $pagination-line-height;
	padding: $pagination-padding-y $pagination-padding-x;
	border: $pagination-border-width solid $pagination-border-color;
	font-size: $pagination-font-size;
	line-height: ($pagination-line-height / $pagination-font-size);
	background: $pagination-bg;
	color: $pagination-color;
	transition: $transition-base;
	border-radius: $pagination-bradius;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		background: linear-gradient(to bottom, transparent, $primary);
		transition: inherit;
		border-radius: inherit;
	}
}

// Hover & Focus & Active State
.page-link {
	&:hover,
	&:focus,
	&:active {
		color: $pagination-hover-color;
		background-color: $pagination-hover-bg;
		border-color: $pagination-hover-border-color;

		&:before { opacity: 0; }
	}
}

.bg-gray-100 {
	.page-link {
		&:hover,
		&:focus,
		&:active {
			background: $white;
		}
	}
}

// Active State
.page-item.active > .page-link {
	&,
	&:hover,
	&:focus,
	&:active {
		color: $pagination-active-color;
		background-color: $pagination-active-bg;
		border-color: $pagination-active-border-color;

		&:before { opacity: 0; }
	}
}

.bg-gray-100 {
	.page-item.active > .page-link {
		&,
		&:hover,
		&:focus,
		&:active {
			background: $white;

			&:before { opacity: 0; }
		}
	}
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
	border-radius: $pagination-bradius;
}

// Disabled State
.page-item.disabled > .page-link {
	&,
	&:hover,
	&:focus,
	&:active {
		color: $pagination-disabled-color;
		background-color: $pagination-disabled-bg;
		border-color: $pagination-disabled-border-color;

		&:before { opacity: 0; }
	}
}

.page-item-control {
	.icon::before {
		font-family: 'FontAwesome';
		font-size: 11px;
		line-height: $pagination-line-height;
	}
}

// Prev control
.page-item-control:first-child {
	.icon::before {
		content: '\f053';
		margin-left: -1px;
	}
}

// Next control
.page-item-control:last-child {
	.icon::before {
		content: '\f054';
		margin-right: -1px;
	}
}


* + .page-navigation { margin-top: 40px; }

@include media-breakpoint-up(md) {
	* + .page-navigation { margin-top: 50px; }
}

@include media-breakpoint-up(xl) {
	* + .page-navigation { margin-top: 71px; }
}