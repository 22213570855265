/*
*
* Owl Carousel
*/

.owl-carousel .animated {
	animation-duration: 1000ms;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	animation-name: fadeOut;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
	transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

* + .owl-carousel { margin-top: 30px; }

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	h2 + .owl-carousel { margin-top: 43px; }
	h2 + .owl-carousel-1,
	h2 + .owl-carousel-3 { margin-top: 59px; }
}

// Extra large ≥1600px
@include media-breakpoint-up(xxl) {
	h2 + .owl-carousel { margin-top: 53px; }
	h2 + .owl-carousel-1,
	h2 + .owl-carousel-3 { margin-top: 72px; }
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
	cursor: pointer;
	user-select: none;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
	display: none;
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.owl-carousel .owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	font: 400 40px/80px 'FontAwesome';
	cursor: pointer;
	z-index: 1;
	transition: scale 100ms ease;
	&:before {
		content: '\f144';
	}
}

.owl-carousel .owl-video-play-icon:hover {
	transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
}

/*
 * Owl Navigation
 */

.owl-nav {
	&.disabled {
		display: none;
	}
}

.owl-prev,
.owl-next {
	position: absolute;
	top: 50%;
	width: 48px;
	height: 48px;
	overflow: hidden;
	line-height: 48px;
	text-align: center;
	transform: translateY(-50%);
	font: 400 40px/48px $mdi;
	color: $gray-600;
	background: transparent;
	border-radius: 50%;
	transition: .3s;
	cursor: pointer;

	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		background: linear-gradient(to bottom, transparent, $primary);
		opacity: 0;
		transition: inherit;
	}

	&:hover {
		color: $white;
		background: $primary-light;

		&:after {
			opacity: 1;
		}
	}
}

.owl-prev {
	left: 0;

	&::before {
		content: '\f237';
	}
}

.owl-next {
	right: 0;

	&::before {
		content: '\f238';
	}
}

/*
 * Owl Pagination
 */

.owl-dots {
	text-align: center;
	margin-top: 20px;

	&.disabled {
		display: none;
	}
}

.owl-dot {
	position: relative;
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-left: 5px;
	margin-right: 5px;
	text-align: center;
	outline: none;
	cursor: pointer;
	background-color: rgba($primary, .4);
	transition: .2s;

	&:only-child {
		display: none;
	}

	&:hover,
	&:focus {
		background-color: $primary;
	}

	&.active {
		background-color: $primary;
	}
}

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	.owl-dots { margin-top: 52px; }

	.owl-dot {
		margin-left: 15px;
		margin-right: 15px;
	}
}

.owl-carousel.owl-carousel-light-dots {
	.owl-dot {
		border-color: $white;

		&:hover,
		&:focus {
			background-color: $white;
		}

		&.active {
			background-color: $white;
		}
	}
}

// Custom styles
.owl-carousel-1 {

	// Medium <768px
	@include media-breakpoint-down(sm) {
	  .owl-nav { display: none; }
	}

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		padding: 0 100px;

		.owl-prev { left: 20px; }
		.owl-next { right: 20px; }

		.owl-dots { display: none; }
	}

	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
	  padding: 0 210px;

		.owl-prev { left: 80px; }
		.owl-next { right: 80px; }
	}
}

.owl-carousel-2 {
	// Medium ≥768px
	@include media-breakpoint-up(sm) {
		.owl-dots {
			position: absolute;
			bottom: 10px;
			right: 9px;
			margin: 0;
		}

		.owl-dot {
			display: block;
			margin-left: 0;
			margin-right: 0;
		}

		.owl-dot + .owl-dot {
			margin-top: 13px;
		}

		.quote-meta {
			padding-right: 15px;
		}
	}
}