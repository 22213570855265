/*
*
* Grid modules
*/

.section-grid-demonstration {
	// Medium ≥768px
	@include media-breakpoint-up(sm) {
		[class^="col"] {
			padding: 0;
		}
	}
	.grid-demonstration-item {
		padding: 5px 0;

		@include media-breakpoint-up(sm) {
			padding: 5px;
			text-align: left;
		}

		@include media-breakpoint-up(md) {
			padding: 15px;
		}

		@include media-breakpoint-down(md) {
			h5 {
				font-size: 16px;
				line-height: 16px;
			}
			p {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}
		}

		@include media-breakpoint-up(lg) {
			padding: 15px 30px;
		}

		@include media-breakpoint-up(xxl) {
			padding: 35px 30px;
		}

	}
	@include media-breakpoint-up(xxl) {
		.container-wide {
			padding-left: 60px;
			padding-right: 60px;
		}
	}
}

// flex col
.row-flex {
	> [class*="col-"] {
		display: flex;

		> * {
			flex-grow: 1;
			max-width: 100%;
		}
	}
}

// Custom Grid
//
// Change $enable-grid-classes-custom: true
// that would generate custom grid (example: 10 columns)

$enable-grid-classes-custom: false;

@mixin make-grid-columns-custom($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
	@each $breakpoint in map-keys($breakpoints) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);

		@include media-breakpoint-up($breakpoint, $breakpoints) {
			@for $i from 1 through $columns {
				.col#{$infix}-#{$i} {
					@include make-col($i, $columns);
				}
			}
		}
	}
}

@if $enable-grid-classes-custom {
	.row-ten {
		@include make-grid-columns-custom(10);
	}

	.row-xl-ten {
		@include make-grid-columns-custom(10, 30px, (xl: map-get($grid-breakpoints, xl), xlg: map-get($grid-breakpoints, xlg), xxl: map-get($grid-breakpoints, xxl)));
	}
}
