/*
*
* Swiper
* --------------------------------------------------
*/

$slide-min-height: calc(100vh - 50px);
$slide-min-height-xs: $slide-min-height;
$slide-min-height-sm: 500px;
$slide-min-height-md: $slide-min-height-sm;
$slide-min-height-lg: 700px;
$slide-min-height-xl: 100vh;


.swiper-container {
	height: auto;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	/* Fix of Webkit flickering */
	z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
	float: left;
}

.swiper-container-vertical > .swiper-wrapper {
	flex-direction: column;
}

.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	transition-property: transform;
	box-sizing: content-box;
}

[data-x-mode="true"] .swiper-wrapper {
	height: auto;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
	transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
	flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
	transition-timing-function: ease-out;
	margin: 0 auto;
}

/* a11y */
.swiper-container .swiper-notification {
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	opacity: 0;
	z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
	touch-action: pan-y;
}

.swiper-wp8-vertical {
	touch-action: pan-x;
}


/* Coverflow */
.swiper-container-coverflow .swiper-wrapper {
	/* Windows 8 IE 10 fix */
}


// Swiper fade effect
//--------------------------------------------------

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
	transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
	pointer-events: none;
}

.swiper-container-fade .swiper-slide .swiper-slide {
	pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
	pointer-events: auto;
}


// Swiper preloader
//--------------------------------------------------

.swiper-lazy-preloader {
	width: 42px;
	height: 42px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -21px;
	margin-top: -21px;
	z-index: 10;
	transform-origin: 50%;
	animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
	display: block;
	content: "";
	width: 100%;
	height: 100%;
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
	background-position: 50%;
	background-size: 100%;
	background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@keyframes swiper-preloader-spin {
	100% { transform: rotate(360deg); }
}

.swiper-slide > .vide__body,
.swiper-slide > .parallax_cnt {
	height: 100%;
}


// Swiper navigation
//--------------------------------------------------

.swiper-button-prev,
.swiper-button-next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);

	display: none;

	font-size: 26px;
	line-height: 1;
	color: $white;

	text-align: center;
	cursor: pointer;
	transition: .2s ease-in;
	will-change: transform;
	z-index: 10;

	&:hover {
		color: $primary;
	}

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		display: block;
	}


	// Disabled button
	&.swiper-button-disabled{
		opacity: 0.35;
		cursor: auto;
		pointer-events: none;
	}
}

.swiper-button-prev {
	left: 30px;

	&:before{
		font-family: $fa;
		content: "\f060";
	}
}

.swiper-button-next {
	right: 30px;

	&:before{
		font-family: $fa;
		content: "\f061";
	}
}


.swiper-slider.swiper-container-rtl{
	.swiper-button-prev {
		&:before{
			content: "\f061";
		}
	}

	.swiper-button-next {
		&:before{
			content: "\f060";
		}
	}
}



// Swiper pagination
//--------------------------------------------------

.swiper-pagination {
	position: absolute;
	display: block;
	text-align: center;
	transition: .3s;
	transform: translate3d(0, 0, 0);
	z-index: 10;

	&.swiper-pagination-hidden {
		opacity: 0;
	}

	.swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		display: inline-block;
		border-radius: 100%;
		background: rgba(255,255,255,.4);
		transition: .2s;

		&.swiper-pagination-bullet-active,
		&:hover{
			background: rgba(255,255,255,1);
		}
	}

	&.swiper-pagination-clickable .swiper-pagination-bullet {
		cursor: pointer;
	}

	// delete if need pagination on resolution >768px
	@include media-breakpoint-up(md) {
		.swiper-navigation + & { display: none; }
	}
}

// Dark pagination
.swiper-pagination-black{
	.swiper-pagination-bullet {
		background: rgba(0,0,0,.6);

		&.swiper-pagination-bullet-active {
			background: rgba(0,0,0,1);
		}
	}
}


// Swiper pagination orientation
//--------------------------------------------------

// Vertical pagination
.swiper-container-vertical{
	height: 100vh;

	> .swiper-pagination {
		right: 10px;
		top: 50%;
		transform: translate3d(0px, -50%, 0);

		.swiper-pagination-bullet {
			margin: 5px 0;
			display: block;
		}
	}
}


// Horizontal pagination
.swiper-container-horizontal{
	> .swiper-pagination {
		bottom: 20px;
		left: 0;
		width: 100%;

		.swiper-pagination-bullet {
			margin: 0 5px;
		}
	}
}

// Swiper slide scrollbar
//--------------------------------------------------
.swiper-scrollbar {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
	background-color: rgba($gray-100, .3);

	.swiper-scrollbar-drag {
		height: 10px;
		background-color: rgba($gray-100, .7);
	}

}


// Swiper slide styles
//--------------------------------------------------

.swiper-slide {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: 100%;
	min-height: $slide-min-height;
	padding: 50px 0;

	background-position: center center;
	background-repeat: no-repeat;

	white-space: nowrap;

	@extend %context-dark;

	&-caption {
		width: 100%;
		white-space: normal;

		p {
			display: none;

			@include media-breakpoint-up(md) {
				display: block;
			}
		}
	}

	@include media-breakpoint-up(md) {
		min-height: $slide-min-height-sm;
	}

	@include media-breakpoint-up(lg) {
		min-height: $slide-min-height-md;
		padding: 60px 0;
	}

	@include media-breakpoint-up(xl) {
		min-height: $slide-min-height-lg;
		padding: 80px 0;
	}

	@include media-breakpoint-up(xxl) {
		min-height: $slide-min-height-xl;
	}
}

// Swiper custom styles
//--------------------------------------------------
.swiper-slider-1 {
	.swiper-slide {
		min-height: $slide-min-height;
		padding: 50px 0 80px;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba($black, .4);
		}

		* + .button { margin-top: 20px; }

		@include media-breakpoint-up(md) {
			min-height: 620px;
			padding: 130px 0;

			* + p { margin-top: 25px; }
			* + .button { margin-top: 36px; }
		}

		@include media-breakpoint-up(lg) {
			min-height: 700px;
			padding: 150px 0;

			&:before {
				display: none;
			}
		}

		@include media-breakpoint-up(xl) {
			min-height: $slide-min-height-lg;

			* + p { margin-top: 28px; }
			* + .button { margin-top: 42px; }

		}

		@include media-breakpoint-up(xxl) {
			min-height: 880px;
			padding: 210px 0;
		}
	}

	// Pagination
	.swiper-pagination-wrap {
		position: relative;
	}

	.swiper-pagination {
		bottom: 30px;
		text-align: left;

		.swiper-pagination-bullet {
			margin: 0 8px;

			&:first-child { margin-left: 0; }
		}

		@include media-breakpoint-up(md) {
			bottom: 80px;

			.swiper-pagination-bullet { margin: 0 15px; }
		}

		@include media-breakpoint-up(lg) {
			bottom: 100px;
		}

		@include media-breakpoint-up(xxl) {
			bottom: 140px;
		}
	}
}

.swiper-slider-2 {

	.swiper-meta {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		pointer-events: none;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		padding: 15px 15px;

		> * {
			pointer-events: auto;
		}
	}

	.links {
		margin-left: -10px;
		margin-top: -16px;

		li {
			display: inline-block;
			margin-top: 16px;
			margin-left: 10px;
		}

		a {
			font-size: 20px;
			line-height: 1;
			color: $gray-400;

			&:hover {
				color: $gray-100;
			}
		}
	}

	.contacts {
		text-align: center;

		.icon {
			font-size: 30px;
			line-height: 1;
			color: $white;
		}

		> * {
			display: block;
		}
	}

	.tel {
		a {
			font-size: 22px;
			line-height: 24px;
			color: $white;

			&:hover {
				color: $primary
			}
		}
	}

	.request {
		a {
			color: $primary-light;

			&:hover,
			&:focus {
				color: $primary;
			}
		}
	}

	.swiper-pagination {
		position: static;

		.swiper-pagination-bullet {
			margin: 0 8px;
		}
	}

	* + .request { margin-top: 4px; }
	* + .tel { margin-top: 10px; }
	.swiper-meta > * + * { margin-top: 15px; }

	.swiper-slide {
		min-height: $slide-min-height;
		padding: 90px 0 220px;
	}

	@include media-breakpoint-up(md) {
		.swiper-slide {
			min-height: 300px;
			padding: 100px 50px 160px;
		}

		.swiper-meta {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			pointer-events: none;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			> * {
				pointer-events: auto;
			}
		}

		.links {
			li { display: block; }
		}

		.contacts {
			align-self: flex-end;
		}

		.swiper-pagination {

			.swiper-pagination-bullet {
				display: block;
				margin: 0;
			}

			.swiper-pagination-bullet + .swiper-pagination-bullet {
				margin-top: 30px;
			}
		}

		.swiper-meta > * + * { margin-top: 0; }
	}

	@include media-breakpoint-up(lg) {
		.swiper-slide {
			min-height: 700px;
			padding: 220px 50px;
		}
	}


	@include media-breakpoint-up(xl) {
		.swiper-slide {
			min-height: $slide-min-height-xl;
			padding: 220px 130px;
		}

		.swiper-meta {
			padding: 55px 67px;
		}

		* + .request { margin-top: 9px; }
		* + .tel { margin-top: 20px; }
	}
}

.swiper-slider-3 {
	.swiper-slide {
		min-height: $slide-min-height;
		padding: 60px 0;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba($black, .4);
		}

		* + .button { margin-top: 20px; }

		@include media-breakpoint-up(md) {
			min-height: 620px;
			padding: 130px 0;

			* + p { margin-top: 25px; }
			* + .button { margin-top: 36px; }
		}

		@include media-breakpoint-up(lg) {
			min-height: 700px;

			&:before {
				display: none;
			}
		}

		@include media-breakpoint-up(xl) {
			min-height: $slide-min-height-lg;

			* + p { margin-top: 35px; }
			* + .button { margin-top: 42px; }

		}

		@include media-breakpoint-up(xxl) {
			min-height: 790px;
		}
	}

	// Pagination
	.swiper-pagination-wrap {
		position: relative;
	}

	.swiper-pagination {
		bottom: 30px;
		text-align: left;

		.swiper-pagination-bullet {
			margin: 0 15px;

			&:first-child { margin-left: 0; }
		}

		@include media-breakpoint-up(md) {
			bottom: 50px;
		}
	}
}