/*
* Gallery
*/

.gallery-item {
	display: block;
	position: relative;
	z-index: 1;
	max-height: 180px;
	overflow: hidden;
	transition: .25s;

	> img {
		width: 100%;
	}

	&:after {
		content: "\f508";
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 1;
		height: 48px;
		width: 48px;
		font-family: $mdi;
		font-size: 28px;
		line-height: 48px;
		text-align: center;
		color: $white;
		background: $primary;
		border-top-left-radius: $border-radius-sm;
		transition: inherit;
	}

	.gallery-item-content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 15px 50px 15px 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: rgba($gray-700, 0.8);
		transition: inherit;

		> * {
			width: 100%;
			color: $white;
		}
	}

	* + .exeption { margin-top: 9px; }

	// Medium <768px
	@include media-breakpoint-down(sm) {
		min-height: 180px;

		> img {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			transform: translate(-50%, -50%);
		}
	}

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		max-height: none;

		* + .exeption { margin-top: 13px; }
	}

	// Large ≥992px
	@include media-breakpoint-up(xl) {
		.gallery-item-content { padding: 3px 50px 15px 61px; }

		* + .exeption { margin-top: 18px; }
	}

	.desktop & {
		.gallery-item-content { opacity: 0; }
		&:after { transform: translateY(100%); }

		&:hover {
			.gallery-item-content { opacity: 1; }
			&:after { transform: translateY(0); }
		}
	}
}

.gallery-item-1 {
	display: block;
	position: relative;
	overflow: hidden;
	border-radius: $border-radius-sm;
	transition: .25s;

	> img {
		width: 100%;
	}

	&:after {
		content: "\f42b";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		font-family: $mdi;
		font-size: 60px;
		color: $white;
		transition: inherit;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba($gray-700, 0.8);
		transition: inherit;
	}

	.desktop & {
		&:before { background: rgba($gray-700, 0); }
		&:after { color: transparent; }

		&:hover {
			&:before { background: rgba($gray-700, 0.8); }
			&:after { color: $white; }
		}
	}
}

// Instagram gallery
.instagram-gallery {
	$offset: 8px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -$offset;
	margin-left: -$offset;

	> li {
		flex-basis: 33.333%;
		max-width: 33.333%;
		padding-left: $offset;
		padding-bottom: $offset;

		> * {
			width: 100%;
			display: block;
		}
	}
}

.instagram-item {
	display: block;
	position: relative;
	padding-bottom: 100%;
	overflow: hidden;
	border-radius: 2px;

	&:before {
		content: "\f504";
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		transform: translate(-50%, -50%);
		font-family: $mdi;
		font-size: 30px;
		line-height: 1;
		color: $white;
		transition: .3s;
		will-change: transform;
	}

	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background: rgba($primary, 0.7);
		transition: .3s;
	}

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		width: auto;
		min-width: 100%;
		min-height: 100%;
		max-width: 110%;
		transform: translate(-50%, -50%);
	}

	.desktop & {
		&:before {
			color: transparent;
			transform: translate(-50%, -50%) scale(0.5);
		}

		&:after { background: rgba($primary, 0); }
		img { transform: translate(-50%, -50%); }

		&:hover {
			&:before {
				color: $white;
				transform: translate(-50%, -50%) scale(1);
			}

			&:after { background: rgba($primary, 0.7); }
			img { transform: translate(-50%, -50%); }
		}
	}
}